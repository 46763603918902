// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const API_BASE = process.env.REACT_APP_API_BASE;

// ** Axios Imports
import axios from 'axios';

export const getAllData = createAsyncThunk('insta/getAllData', async () => {
  const path = `${API_BASE}/api/insta/profile/list/all-data`;
  const response = await axios.get(path);
  return response.data;
});

export const getData = createAsyncThunk('insta/getData', async params => {
  const path = `${API_BASE}/api/insta/profile/list/data`;
  const response = await axios.get(path, { params });
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total,
  };
});

export const getUser = createAsyncThunk('insta/getUser', async id => {
  const path = `${API_BASE}/api/insta/user`;
  const response = await axios.get(path, { params: { id } });
  return response.data.user;
});

export const refreshUser = createAsyncThunk('insta/refreshUser', async id => {
  const path = `${API_BASE}/api/insta/refreshUser`;
  const response = await axios.get(path, { params: { id } });
  return response.data.user;
});

export const addUser = createAsyncThunk('insta/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user);
  await dispatch(getData(getState().users.params));
  await dispatch(getAllData());
  return user;
});

export const deleteUser = createAsyncThunk('insta/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id });
  await dispatch(getData(getState().users.params));
  await dispatch(getAllData());
  return id;
});

export const instaSlice = createSlice({
  name: 'insta',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.users;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
      });
  },
});

export default instaSlice.reducer;
