// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const API_BASE = process.env.REACT_APP_API_BASE;

// ** Axios Imports
import axios from 'axios';

export const getArticles = createAsyncThunk('appExploreDigital/getArticles', async params => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/articledigital/getArticles';
  const response = await axios.post(path, { params });
  return {
    params,
    data: response.data,
  };
});

export const updateArticles = createAsyncThunk('appExploreDigital/updateArticles', async ({ articleIds, dataToUpdate }, { dispatch, getState }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/articledigital/update-articles';
  const response = await axios.post(path, { articleIds, dataToUpdate });
  await dispatch(getArticles(getState().exploredigital.params));
  return {
    articleIds,
    dataToUpdate,
    data: response.data,
  };
});

export const mailArticles = createAsyncThunk('appExploreDigital/mailArticles', async ({ articleIds, dataToSend }, { dispatch, getState }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/mail/mail-query-articledigital';
  const response = await axios.post(path, { articleIds, dataToSend });
  await dispatch(getArticles(getState().exploredigital.params));
  return {
    articleIds,
    dataToSend,
    data: response.data,
  };
});

export const importArticles = createAsyncThunk('appExploreDigital/importArticles', async ({ articleIds }, { dispatch, getState }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/articledigital/import-articles';
  const response = await axios.post(path, { articleIds });
  await dispatch(getArticles(getState().exploredigital.params));
  return {
    articleIds,
    data: response.data,
  };
});

export const updateMailLabel = createAsyncThunk('appExploreDigital/updateMailLabel', async ({ articleIds, label }, { dispatch, getState }) => {
  const response = await axios.post('/apps/article/update-articles-label', { articleIds, label });
  await dispatch(getArticles(getState().exploredigital.params));
  return response.data;
});

export const paginateArticle = createAsyncThunk('appExploreDigital/paginateArticle', async ({ dir, articleId }) => {
  const response = await axios.get('/apps/article/paginate-article', { params: { dir, articleId } });
  return response.data;
});

export const selectCurrentArticle = createAsyncThunk('appExploreDigital/selectCurrentArticle', async id => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/articledigital/get-article';
  const response = await axios.get(path, { params: { id } });
  return response.data.article;
});

export const searchNews = createAsyncThunk('appExploreDigital/searchNews', async (data, { dispatch, getState }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/searchNews';
  const response = await axios.post(path, data);

  await dispatch(getArticles(getState().exploredigital.params));
  return {
    data: response.data,
  };
});

export const searchNewsbyParams = createAsyncThunk('appExploreDigital/searchNews/batch', async params => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/articledigital/searchNews';
  const response = await axios.post(path, params);

  //await dispatch(getArticles(getState().exploredigital.params))
  return {
    data: response.data,
  };
});

//** Organisation functions
export const getAllOrganisationData = createAsyncThunk('explore/getAllOrganisationData', async () => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/all-data';
  const response = await axios.get(path);
  return response.data;
});

export const selectOrganisation = createAsyncThunk('explore/selectOrganisation', async id => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/project/data';
  const response = await axios.get(path, { params: { id } });
  return response.data;
});

export const selectProject = createAsyncThunk('explore/selectProject', async id => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/project/id';
  const response = await axios.get(path, { params: { id } });
  return response.data;
});

export const appExploreDigitalSlice = createSlice({
  name: 'appExploreDigital',
  initialState: {
    articles: [],
    params: {},
    articlesMeta: {},
    selectedArticles: [],
    currentArticle: null,
    searchLoading: true,
    organisations: [],
    selectedOrganisation: null,
    selectedProject: null,
  },
  reducers: {
    selectArticle: (state, action) => {
      const selectedArticles = state.selectedArticles;
      if (!selectedArticles.includes(action.payload)) {
        selectedArticles.push(action.payload);
      } else {
        selectedArticles.splice(selectedArticles.indexOf(action.payload), 1);
      }
      state.selectedArticles = selectedArticles;
    },
    selectAllArticle: (state, action) => {
      const selectAllArticlesArr = [];
      if (action.payload) {
        selectAllArticlesArr.length = 0;
        state.articles.forEach(article => selectAllArticlesArr.push(article._id));
      } else {
        selectAllArticlesArr.length = 0;
      }
      state.selectedArticles = selectAllArticlesArr;
    },
    resetSelectedArticle: state => {
      state.selectedArticles = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getArticles.fulfilled, (state, action) => {
        let currArticle = null;
        if (state.currentArticle !== null && state.currentArticle !== undefined) {
          currArticle = action.payload.data.articles.find(i => i._id === state.currentArticle._id);
        }
        state.currentArticle = currArticle;
        state.params = action.payload.params;
        state.articles = action.payload.data.articles;
      })
      .addCase(updateArticles.fulfilled, (state, action) => {
        function updateArticleData(article) {
          Object.assign(article, action.payload.dataToUpdate);
        }
        state.articles.forEach(article => {
          if (action.payload.articleIds.includes(article._id)) {
            updateArticleData(article);
          }
        });
      })
      .addCase(paginateArticle.fulfilled, (state, action) => {
        const data = action.payload;
        const dataIndex = state.articles.findIndex(i => i.id === data.id);
        dataIndex === 0 ? (data.hasPreviousArticle = false) : (data.hasPreviousArticle = true);
        dataIndex === state.articles.length - 1 ? (data.hasNextArticle = false) : (data.hasNextArticle = true);
        state.currentArticle = data;
      })
      .addCase(selectCurrentArticle.fulfilled, (state, action) => {
        state.currentArticle = action.payload;
      })
      // eslint-disable-next-line
      .addCase(searchNews.fulfilled, (state, action) => {
        state.searchLoading = false;
      })

      .addCase(getAllOrganisationData.fulfilled, (state, action) => {
        state.organisations = action.payload;
      })
      .addCase(selectOrganisation.fulfilled, (state, action) => {
        state.selectedOrganisation = action.payload;
      })
      .addCase(selectProject.fulfilled, (state, action) => {
        state.selectedProject = action.payload;
      });
  },
});

export const { selectArticle, selectAllArticle, resetSelectedArticle, searchLoading } = appExploreDigitalSlice.actions;

export default appExploreDigitalSlice.reducer;
