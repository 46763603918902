// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';
const API_BASE = process.env.REACT_APP_API_BASE;

//** project functions
export const getAllProjectData = createAsyncThunk('organisations/projects/getAllProjectData', async () => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/project/all-data';
  const response = await axios.get(path);
  return response.data;
});

export const getOrganisationProjects = createAsyncThunk('organisations/projects/All', async id => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/project/data';
  const response = await axios.get(path, { params: { id } });
  return response.data.projects;
});

export const getOrganisationUsers = createAsyncThunk('users/by-organisation', async id => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/users/by-organisation';
  const response = await axios.get(path, { params: { id } });
  return response.data.users;
});

export const getOrganisationCategories = createAsyncThunk('organisation/get-categories', async id => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/get-categories';
  const response = await axios.get(path, { params: { id } });
  return response.data;
});

export const getOrganisationTypes = createAsyncThunk('organisation/get-types', async id => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/get-types';
  const response = await axios.get(path, { params: { id } });
  return response.data;
});

export const getOrganisationItems = createAsyncThunk('organisation/get-items', async id => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/get-items';
  const response = await axios.get(path, { params: { id } });
  return response.data;
});

//** Organisation functions
export const getAllOrganisationData = createAsyncThunk('organisations/getAllOrganisationData', async () => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/all-data';
  const response = await axios.get(path);
  return response.data;
});

export const getOrganisationData = createAsyncThunk('organisations/getOrganisationData', async params => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/data';
  const response = await axios.get(path, params);
  return {
    params,
    data: response.data.organisations,
    totalPages: response.total,
  };
});

export const getOrganisation = createAsyncThunk('organisations/getOrganisation', async (id, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/organisation';
  const response = await axios.get(path, { params: { id } });
  await dispatch(getOrganisationProjects(id));
  await dispatch(getOrganisationUsers(id));
  await dispatch(getOrganisationCategories(id));
  await dispatch(getOrganisationTypes(id));
  await dispatch(getOrganisationItems(id));
  return response.data;
});

export const addOrganisation = createAsyncThunk('organisations/addOrganisation', async (organisation, { dispatch, getState }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/';
  await axios.post(path, organisation);
  await dispatch(getOrganisationData(getState().organisations.params));
  await dispatch(getAllOrganisationData());
  return organisation;
});

export const updateOrganisation = createAsyncThunk('organisations/updateOrganisation', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/';
  await axios.put(path, data);
  await dispatch(getOrganisation(data.id));
  //await dispatch(getOrganisationData(getState().organisations.params))
  // await dispatch(getAllOrganisationData())
  //return organisation
});

export const deleteOrganisation = createAsyncThunk('organisations/deleteUser', async (id, { dispatch, getState }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/delete/';
  await axios.delete(path, { params: { id } });
  await dispatch(getOrganisationData(getState().organisations.params));
  await dispatch(getAllOrganisationData());
  return id;
});

export const updateProject = createAsyncThunk('organisations/updateProject', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/project';
  await axios.put(path, data);
  await dispatch(getOrganisation(data.idOrganisation));
});

export const updateUser = createAsyncThunk('organisations/updateUser', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/users';
  await axios.put(path, data);
  await dispatch(getOrganisation(data.idOrganisation));
});

export const createProject = createAsyncThunk('organisations/createProject', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/project';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisation));
});

export const createUser = createAsyncThunk('organisations/createUser', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/users';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisation));
});

// eslint-disable-next-line
export const deleteProject = createAsyncThunk('organisations/deleteProject', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/project/delete/';
  await axios.delete(path, { params: { id: data._id } });
  await dispatch(getOrganisation(data.organisation));
});

// eslint-disable-next-line
export const deleteUser = createAsyncThunk('organisations/deleteUser', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/users/delete/';
  await axios.delete(path, { params: { id: data._id } });
  await dispatch(getOrganisation(data.organisation));
});

// eslint-disable-next-line
export const addKeyword = createAsyncThunk('keyword/addKeyword', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/keyword/';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisation));
});

// eslint-disable-next-line
export const deleteKeyword = createAsyncThunk('keyword/deleteKeyword', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/keyword/delete-keyword';
  await axios.get(path, { params: { keyword: data.keyword } });
  await dispatch(getOrganisation(data.organisation));
});

// eslint-disable-next-line
export const deleteLocation = createAsyncThunk('organisation/deleteLocation', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/delete-location';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisationId));
});

// eslint-disable-next-line
export const deletePerson = createAsyncThunk('organisation/deletePerson', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/delete-person';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisationId));
});

// eslint-disable-next-line
export const deleteEntity = createAsyncThunk('organisation/deleteEntity', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/delete-entity';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisationId));
});

// eslint-disable-next-line
export const deleteMedia = createAsyncThunk('organisation/deleteMedia', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/delete-media';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisationId));
});

// eslint-disable-next-line
export const addLocation = createAsyncThunk('organisation/addLocation', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/exclude-location';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisation));
});

// eslint-disable-next-line
export const addPersons = createAsyncThunk('organisation/addPersons', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/exclude-persons';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisation));
});

// eslint-disable-next-line
export const addEntities = createAsyncThunk('organisation/addEntities', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/exclude-entities';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisation));
});

// eslint-disable-next-line
export const addMedia = createAsyncThunk('organisation/addMedia', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/add-media';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisation));
});

// eslint-disable-next-line
export const addCategory = createAsyncThunk('organisation/addCategory', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/add-category';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisation));
});

// eslint-disable-next-line
export const updateCategory = createAsyncThunk('organisation/updateCategory', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/update-category';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisation));
});

export const deleteCategory = createAsyncThunk('organisations/deleteCategory', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/delete-category/';
  await axios.delete(path, { params: { organisationId: data.organisationId, categoryId: data.categoryId } });
  await dispatch(getOrganisation(data.organisationId));
});

// eslint-disable-next-line
export const addType = createAsyncThunk('organisation/addType', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/add-type';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisation));
});

export const deleteType = createAsyncThunk('organisations/deleteType', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/delete-type/';
  await axios.delete(path, { params: { organisationId: data.organisationId, typeId: data.typeId } });
  await dispatch(getOrganisation(data.organisationId));
});

export const addItem = createAsyncThunk('organisation/addItem', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/add-item';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisation));
});

export const updateItem = createAsyncThunk('organisation/updateItem', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/update-item';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisation));
});

export const deleteItem = createAsyncThunk('organisations/deleteItem', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/delete-item/';
  await axios.delete(path, { params: { organisationId: data.organisationId, itemId: data.itemId } });
  await dispatch(getOrganisation(data.organisationId));
});

// eslint-disable-next-line
export const updateKeyword = createAsyncThunk('keyword/updateKeyword', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/keyword/update-keyword';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisation));
});

export const getProjectKeywords = createAsyncThunk('keyword/data', async id => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/keyword/data';
  const response = await axios.get(path, { params: { id } });
  return response.data.keywords;
});

export const sendItemsCSV = createAsyncThunk('organisation/sendItemsCSV', async (data, { dispatch }) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/organisation/add-items-csv';
  await axios.post(path, data);
  await dispatch(getOrganisation(data.organisation));
});

export const organisationsSlice = createSlice({
  name: 'organisations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    orgCategories: [],
    orgTypes: [],
    orgItems: [],
    allData: [],
    selectedOrganisation: null,
    orgaProjects: null,
    orgaUsers: null,
    tempKeywords: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllOrganisationData.fulfilled, (state, action) => {
        state.allData = action.payload;
      })
      .addCase(getOrganisationData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
      })
      .addCase(getOrganisation.fulfilled, (state, action) => {
        state.selectedOrganisation = action.payload;
      })
      .addCase(getOrganisationProjects.fulfilled, (state, action) => {
        state.orgaProjects = action.payload;
      })
      .addCase(getOrganisationUsers.fulfilled, (state, action) => {
        state.orgaUsers = action.payload;
      })
      .addCase(getOrganisationCategories.fulfilled, (state, action) => {
        state.orgCategories = action.payload;
      })
      .addCase(getOrganisationTypes.fulfilled, (state, action) => {
        state.orgTypes = action.payload;
      })
      .addCase(getOrganisationItems.fulfilled, (state, action) => {
        state.orgItems = action.payload;
      })
      .addCase(getProjectKeywords.fulfilled, (state, action) => {
        state.tempKeywords = action.payload;
      });
  },
});
export default organisationsSlice.reducer;
