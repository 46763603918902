const API_BASE = process.env.REACT_APP_API_BASE;
// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

//** Organisation functions
export const getAllOrganisationData = createAsyncThunk(
  "appTwitter/getAllOrganisationData",
  async () => {
    const path = `${API_BASE}/api/organisation/all-data`;
    const response = await axios.get(path);
    return response.data;
  }
);

export const getUserOrganisation = createAsyncThunk(
  "/appTwitter/getUserOrganisation",
  async (userId) => {
    const path = `${API_BASE}/api/organisation/user-organisation-data/${userId}`;
    const response = await axios.get(path);
    return response.data;
  }
);

export const selectOrganisation = createAsyncThunk("appTwitter/selectOrganisation", async (id) => {
  const path = `${API_BASE}/api/project/data`;
  const response = await axios.get(path, { params: { id } });
  return response.data;
});

export const selectProject = createAsyncThunk("appTwitter/selectProject", async (id) => {
  const path = `${API_BASE}/api/project/id`;
  const response = await axios.get(path, { params: { id } });
  return response.data;
});

export const getTweets = createAsyncThunk("appExploreYouTubel/getVideos", async (params) => {
  const path = `${API_BASE}/api/twitter/get-tweets`;
  const response = await axios.post(path, { params });
  return {
    params,
    data: response.data,
  };
});

export const getVideos = createAsyncThunk("appExploreYouTubel/getVideos", async (params) => {
  const path = `${API_BASE}/api/youtube/get-videos`;
  const response = await axios.post(path, { params });
  return {
    params,
    data: response.data,
  };
});

export const getArticles = createAsyncThunk("appExploreYouTubel/getArticles", async (params) => {
  const path = `${API_BASE}/api/youtube/getArticlesDigital`;
  const response = await axios.post(path, { params });
  return {
    params,
    data: response.data,
  };
});

export const getArticle = createAsyncThunk(
  "appExploreYouTubel/selectCurrentArticle",
  async (id) => {
    // eslint-disable-next-line
    const path = API_BASE + "/api/youtube/get-article";
    const response = await axios.get(path, { params: { id } });
    return response.data;
  }
);

export const mailTweets = createAsyncThunk("appExploreYouTubel/mailTweets", async (params) => {
  // eslint-disable-next-line
  const path = API_BASE + "/api/twitter/mail-query";
  const response = await axios.post(path, { params });

  return {
    params,
    data: response.data,
  };
});

export const searchTweets = createAsyncThunk("appTwitter/searchTweets", async (params) => {
  const path = `${API_BASE}/api/twitter/search-tweets`;
  const response = await axios.post(path, { params });

  return {
    params,
    data: response.data,
  };
});

export const searchNewsAdvanced = createAsyncThunk("appTwitter/searchNews", async (params) => {
  const path = `${API_BASE}/api/youtube/search-youtube`;
  const response = await axios.post(path, { params });

  return {
    params,
    data: response.data,
  };
});

export const getPdfData = createAsyncThunk("appExploreYouTubel/pdfData", async (params) => {
  // eslint-disable-next-line
  const path = API_BASE + "/api/youtube/pdfvideoData";
  const response = await axios.post(path, { params });

  return {
    params,
    data: response.data,
  };
});

const moveToTrash = (state, action) => {
  state.tweets = state.tweets.filter((e) => e._id !== action.payload);
  const path = API_BASE + "/api/twitter/moveToTrash";
  axios.post(path, { id: action.payload });
};

export const appTwitterSlice = createSlice({
  name: "appTwitter",
  initialState: {
    cart: [],
    params: {},
    articles: [],
    tweets: [],
    currentArticle: null,
    totalArticles: 0,
    totalTweets: 0,
    articleDetail: {},
    organisation: "",
    organisations: [],
    selectedOrganisation: null,
    loading: false,
  },
  reducers: {
    moveToTrash,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTweets.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getTweets.fulfilled, (state, action) => {
        state.params = action.payload.params;
        state.tweets = action.payload.data.tweets;
        state.totalTweets = action.payload.data.total;
        state.loading = false;
      })
      .addCase(getArticle.fulfilled, (state, action) => {
        state.articleDetail = action.payload.article;
      })
      .addCase(getAllOrganisationData.fulfilled, (state, action) => {
        state.organisations = action.payload;
      })
      .addCase(selectOrganisation.fulfilled, (state, action) => {
        state.selectedOrganisation = action.payload;
      })
      .addCase(getUserOrganisation.fulfilled, (state, action) => {
        state.organisation = action.payload;
      });
  },
});

export const { actions } = appTwitterSlice;
export default appTwitterSlice.reducer;
