const API_BASE = process.env.REACT_APP_API_BASE;
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';

//** Organisation functions
export const getAllOrganisationData = createAsyncThunk(
  'appDigitalPress/getAllOrganisationData',
  async () => {
    const path = `${API_BASE}/api/organisation/all-data`;
    const response = await axios.get(path);
    return response.data;
  }
);

export const getUserOrganisation = createAsyncThunk(
  '/appTwitter/getUserOrganisation',
  async (userId) => {
    const path = `${API_BASE}/api/organisation/user-organisation-data/${userId}`;
    const response = await axios.get(path);
    return response.data;
  }
);

export const selectOrganisation = createAsyncThunk(
  'appDigitalPress/selectOrganisation',
  async (id) => {
    const path = `${API_BASE}/api/project/data`;
    const response = await axios.get(path, { params: { id } });
    return response.data;
  }
);

export const selectProject = createAsyncThunk('appDigitalPress/selectProject', async (id) => {
  const path = `${API_BASE}/api/project/id`;
  const response = await axios.get(path, { params: { id } });
  return response.data;
});

export const getArticles = createAsyncThunk('appExploreDigital/getArticles', async (params) => {
  const path = `${API_BASE}/api/articledigital/getArticlesDigital`;
  const response = await axios.post(path, { params });
  return {
    params,
    data: response.data,
  };
});

export const getArticle = createAsyncThunk('appExploreDigital/selectCurrentArticle', async (id) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/articledigital/get-article';
  const response = await axios.get(path, { params: { id } });
  return response.data;
});

export const mailArticles = createAsyncThunk('appExploreDigital/mailArticles', async (params) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/articledigital/mail-query-articledigital';
  const response = await axios.post(path, { params });

  return {
    params,
    data: response.data,
  };
});

export const generateReport = createAsyncThunk(
  'appExploreDigital/generateReport',
  async (params) => {
    // eslint-disable-next-line
    const path = API_BASE + '/api/articledigital/generate-pdf-report';
    const response = await axios.post(path, { params });

    return {
      params,
      data: response,
    };
  }
);

export const searchNewsAdvanced = createAsyncThunk(
  'appDigitalPress/searchNews',
  async (params, { dispatch, getState }) => {
    const path = `${API_BASE}/api/articledigital/search-news-advanced`;
    const response = await axios.post(path, { params });
    await dispatch(getArticles(getState().digitalpress.params));
    return {
      params,
      data: response.data,
    };
  }
);

export const getPdfData = createAsyncThunk('appExploreDigital/pdfData', async (params) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/articledigital/pdfData';
  const response = await axios.post(path, { params });

  return {
    params,
    data: response.data,
  };
});

export const getProducts = createAsyncThunk('appDigitalPress/getProducts', async (params) => {
  const response = await axios.get('/apps/ecommerce/products', { params });
  return { params, data: response.data };
});

export const addToCart = createAsyncThunk(
  'appDigitalPress/addToCart',
  async (id, { dispatch, getState }) => {
    const response = await axios.post('/apps/ecommerce/cart', { productId: id });
    await dispatch(getProducts(getState().ecommerce.params));
    return response.data;
  }
);

export const getWishlistItems = createAsyncThunk('appDigitalPress/getWishlistItems', async () => {
  const response = await axios.get('/apps/ecommerce/wishlist');
  return response.data;
});

export const deleteWishlistItem = createAsyncThunk(
  'appDigitalPress/deleteWishlistItem',
  async (id, { dispatch }) => {
    const response = await axios.delete(`/apps/ecommerce/wishlist/${id}`);
    dispatch(getWishlistItems());
    return response.data;
  }
);

export const updateStatus = createAsyncThunk('appDigitalPress/updateStatus', async (data) => {
  // eslint-disable-next-line
  const path = API_BASE + '/api/articledigital/update-status';
  const response = await axios.post(path, data);
  return response;
});

export const getCartItems = createAsyncThunk('appDigitalPress/getCartItems', async () => {
  const response = await axios.get('/apps/ecommerce/cart');
  return response.data;
});

export const getProduct = createAsyncThunk('appDigitalPress/getProduct', async (slug) => {
  const response = await axios.get(`/apps/ecommerce/products/${slug}`);
  return response.data;
});

export const addToWishlist = createAsyncThunk('appDigitalPress/addToWishlist', async (id) => {
  await axios.post('/apps/ecommerce/wishlist', { productId: id });
  return id;
});

export const deleteCartItem = createAsyncThunk(
  'appDigitalPress/deleteCartItem',
  async (id, { dispatch }) => {
    await axios.delete(`/apps/ecommerce/cart/${id}`);
    dispatch(getCartItems());
    return id;
  }
);

export const populateCustomItems = createAsyncThunk(
  'appEDigitalPress/populateCustomItems',
  async (params, { dispatch, getState }) => {
    // eslint-disable-next-line
    const path = API_BASE + '/api/articledigital/populate-custom-items';
    const response = await axios.post(path, { params });
    await dispatch(getArticles(getState().digitalpress.params));

    return {
      params,
      data: response.data,
    };
  }
);

export const uploadPDF = createAsyncThunk(
  'articledigital/uploadPDF',
  async (data, { dispatch }) => {
    // eslint-disable-next-line
    const path = API_BASE + '/api/articledigital/upload-pdf';
    await axios.post(path, data);
    await dispatch(getArticles(getState().digitalpress.params));
  }
);

export const selectCustomCategoriesItems = createAsyncThunk(
  'admindashboard/selectCustomCategoriesItems',
  async (catItemsData) => {
    // eslint-disable-next-line
    const path = API_BASE + '/api/organisation/select-categories-items';
    const response = await axios.post(path, catItemsData);
    return response.data;
  }
);

export const appDigitalPressSlice = createSlice({
  name: 'appDigitalPress',
  initialState: {
    cart: [],
    params: {},
    products: [],
    articles: [],
    currentArticle: null,
    wishlist: [],
    totalProducts: 0,
    totalArticles: 0,
    productDetail: {},
    articleDetail: {},
    organisations: [],
    customItemsList: [],
    selectedOrganisation: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getArticles.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getArticles.fulfilled, (state, action) => {
        state.params = action.payload.params;
        state.articles = action.payload.data.articles;
        state.totalArticles = action.payload.data.total;
        state.loading = false;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.params = action.payload.params;
        state.products = action.payload.data.products;
        state.totalProducts = action.payload.data.total;
      })
      .addCase(getWishlistItems.fulfilled, (state, action) => {
        state.wishlist = action.payload.products;
      })
      .addCase(getCartItems.fulfilled, (state, action) => {
        state.cart = action.payload.products;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.productDetail = action.payload.product;
      })
      .addCase(getArticle.fulfilled, (state, action) => {
        state.articleDetail = action.payload.article;
      })
      .addCase(getAllOrganisationData.fulfilled, (state, action) => {
        state.organisations = action.payload;
      })
      .addCase(selectOrganisation.fulfilled, (state, action) => {
        state.selectedOrganisation = action.payload;
      })
      .addCase(getUserOrganisation.fulfilled, (state, action) => {
        state.organisation = action.payload;
      })
      .addCase(selectCustomCategoriesItems.fulfilled, (state, action) => {
        state.customItemsList = action.payload;
      });
  },
});

export default appDigitalPressSlice.reducer;
