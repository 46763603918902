// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import snippets from '@src/views/snippets/store';
import organisations from '@src/views/settings/organisations/store';
import exploredigital from '@src/views/exploredigital/store';
import users from '@src/views/apps/user/store';
import ecommerce from '@src/views/apps/ecommerce/store';
import digitalpress from '@src/views/digitalpress/store';
import youtube from '@src/views/youtube/store';
import twitter from '@src/views/twitter/store';
import permissions from '@src/views/apps/roles-permissions/store';
import admindashboard from '@src/views/admin-dashboard/store';
import admindashboardyoutube from '@src/views/admin-dashboard-youtube/store';
import admindashboardtwitter from '@src/views/admin-dashboard-twitter/store';
import insta from '@src/views/insta/store';

const rootReducer = {
  auth,
  snippets,
  organisations,
  exploredigital,
  
  admindashboard,
  admindashboardyoutube,
  admindashboardtwitter,
  digitalpress,
  youtube,
  twitter,

  insta,
  users,
  navbar,
  layout,
  permissions,
  ecommerce,
};

export default rootReducer;
