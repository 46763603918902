// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

const query = {
  keyword: "السجل الاجتماعي الموحد",
  startDate: "2020-12-01T12:09:07.336+00:00",
  endDate: "2021-12-22T12:09:07.336+00:00",
};

export const getSnippets = createAsyncThunk("snippets/getSnippets", async () => {
  const response = await axios.post("http://localhost:4000/api/getSnippets", query);

  // console.log(response);
  return response.data.data;
});

export const getTasks = createAsyncThunk("appTodo/getTasks", async (params) => {
  const response = await axios.get("/apps/todo/tasks", { params });

  return {
    params,
    data: response.data,
  };
});

export const addTask = createAsyncThunk("appTodo/addTask", async (task, { dispatch, getState }) => {
  const response = await axios.post("/apps/todo/add-tasks", { task });
  await dispatch(getTasks(getState().todo.params));
  return response.data;
});

export const updateTask = createAsyncThunk(
  "appTodo/updateTask",
  async (task, { dispatch, getState }) => {
    const response = await axios.post("/apps/todo/update-task", { task });
    await dispatch(getTasks(getState().todo.params));
    return response.data;
  }
);

export const deleteTask = createAsyncThunk(
  "appTodo/deleteTask",
  async (taskId, { dispatch, getState }) => {
    const response = await axios.delete("/apps/todo/delete-task", { taskId });
    await dispatch(getTasks(getState().todo.params));
    return response.data;
  }
);

export const appSnippetsSlice = createSlice({
  name: "snippets",
  initialState: {
    tasks: [],
    snippets: [],
    selectedTask: {},
    params: {
      filter: "",
      q: "",
      sort: "",
      tag: "",
    },
  },
  reducers: {
    reOrderTasks: (state, action) => {
      state.tasks = action.payload;
    },
    selectTask: (state, action) => {
      state.selectedTask = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTasks.fulfilled, (state, action) => {
      state.tasks = action.payload.data;
      state.params = action.payload.params;
    });
    builder.addCase(getSnippets.fulfilled, (state, action) => {
      state.snippets = action.payload;
    });
  },
});

export const { reOrderTasks, selectTask } = appSnippetsSlice.actions;

export default appSnippetsSlice.reducer;
