const API_BASE = process.env.REACT_APP_API_BASE;
// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

//** Organisation functions
export const getAllOrganisationData = createAsyncThunk(
  "appYouTube/getAllOrganisationData",
  async () => {
    const path = `${API_BASE}/api/organisation/all-data`;
    const response = await axios.get(path);
    return response.data;
  }
);

export const getUserOrganisation = createAsyncThunk(
  "/appYoutube/getUserOrganisation",
  async (userId) => {
    const path = `${API_BASE}/api/organisation/user-organisation-data/${userId}`;
    const response = await axios.get(path);
    return response.data;
  }
);

export const selectOrganisation = createAsyncThunk("appYouTube/selectOrganisation", async (id) => {
  const path = `${API_BASE}/api/project/data`;
  const response = await axios.get(path, { params: { id } });
  return response.data;
});

export const selectProject = createAsyncThunk("appYouTube/selectProject", async (id) => {
  const path = `${API_BASE}/api/project/id`;
  const response = await axios.get(path, { params: { id } });
  return response.data;
});

export const getVideos = createAsyncThunk("appExploreYouTubel/getVideos", async (params) => {
  const path = `${API_BASE}/api/youtube/get-videos`;
  const response = await axios.post(path, { params });
  return {
    params,
    data: response.data,
  };
});

export const getArticles = createAsyncThunk("appExploreYouTubel/getArticles", async (params) => {
  const path = `${API_BASE}/api/youtube/getArticlesDigital`;
  const response = await axios.post(path, { params });
  return {
    params,
    data: response.data,
  };
});

export const getArticle = createAsyncThunk(
  "appExploreYouTubel/selectCurrentArticle",
  async (id) => {
    // eslint-disable-next-line
    const path = API_BASE + "/api/youtube/get-article";
    const response = await axios.get(path, { params: { id } });
    return response.data;
  }
);

export const mailArticles = createAsyncThunk("appExploreYouTubel/mailArticles", async (params) => {
  // eslint-disable-next-line
  const path = API_BASE + "/api/youtube/mail-query-articledigital";
  const response = await axios.post(path, { params });

  return {
    params,
    data: response.data,
  };
});

export const mailVideos = createAsyncThunk("appExploreYouTubel/mailVideos", async (params) => {
  // eslint-disable-next-line
  const path = API_BASE + "/api/youtube/mail-query-youtubevideo";
  const response = await axios.post(path, { params });

  return {
    params,
    data: response.data,
  };
});

export const searchYoutubeAdvanced = createAsyncThunk("appYouTube/searchNews", async (params) => {
  const path = `${API_BASE}/api/youtube/search-youtube`;
  const response = await axios.post(path, { params });
  getVideos();
  return {
    params,
    data: response.data,
  };
});

export const getPdfData = createAsyncThunk("appExploreYouTubel/pdfData", async (params) => {
  // eslint-disable-next-line
  const path = API_BASE + "/api/youtube/pdfvideoData";
  const response = await axios.post(path, { params });

  return {
    params,
    data: response.data,
  };
});

const moveToTrash = (state, action) => {
  state.videos = state.videos.filter((e) => e._id !== action.payload);
  const path = API_BASE + "/api/youtube/moveToTrash";
  // axios.post(path, { id: action.payload });
};

export const appYouTubeSlice = createSlice({
  name: "appYouTube",
  initialState: {
    cart: [],
    params: {},
    articles: [],
    videos: [],
    currentArticle: null,
    totalArticles: 0,
    totalVideos: 0,
    articleDetail: {},
    organisations: [],
    organisation: "",
    selectedOrganisation: null,
    loading: false,
  },
  reducers: {
    moveToTrash,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVideos.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getVideos.fulfilled, (state, action) => {
        state.params = action.payload.params;
        state.videos = action.payload.data.videos;
        state.totalVideos = action.payload.data.total;
        state.loading = false;
      })
      .addCase(getArticle.fulfilled, (state, action) => {
        state.articleDetail = action.payload.article;
      })
      .addCase(getAllOrganisationData.fulfilled, (state, action) => {
        state.organisations = action.payload;
      })
      .addCase(selectOrganisation.fulfilled, (state, action) => {
        state.selectedOrganisation = action.payload;
      })
      .addCase(getUserOrganisation.fulfilled, (state, action) => {
        state.organisation = action.payload;
      });
  },
});

export const { actions } = appYouTubeSlice;
export default appYouTubeSlice.reducer;
